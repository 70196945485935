import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick", "title"]
const _hoisted_2 = {
  key: 0,
  class: "bi bi-x-circle-fill fs-4"
}
const _hoisted_3 = {
  key: 1,
  class: "bi bi-check-circle-fill fs-4"
}
const _hoisted_4 = {
  hidden: "",
  ref: "openModal",
  class: "btn btn-primary er fs-6",
  "data-bs-toggle": "modal",
  "data-bs-target": "#new-way-to-pay-form"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CrudActionsV2 = _resolveComponent("CrudActionsV2")!
  const _component_Datarow = _resolveComponent("Datarow")!
  const _component_Datatable = _resolveComponent("Datatable")!
  const _component_ModuleMaster = _resolveComponent("ModuleMaster")!
  const _component_NewWayToPayForm = _resolveComponent("NewWayToPayForm")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_ModuleMaster, {
      requireSearchField: true,
      requireAddButton: true,
      requirePaginator: true,
      useFilters: false,
      labels: _ctx.labels,
      numItemsPaginator: _ctx.totalRecords,
      selectedPage: _ctx.selectedPage,
      itemsPerPage: _ctx.itemsPerPage,
      fnCallbackChangePage: _ctx.handleChangePage,
      fnCallbackCrudAction: _ctx.handleCrudAction,
      fnCallbackSearchItems: _ctx.searchItems
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Datatable, {
          headers: _ctx.headers,
          requireActions: true,
          requireStatusCol: true
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.renderRows, (row) => {
              return (_openBlock(), _createBlock(_component_Datarow, {
                key: row.ID,
                dataRow: row,
                showCrudActions: true,
                requireStatusCol: true,
                statusPropName: 'isActive'
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_CrudActionsV2, {
                    onActionCrud: ($event: any) => (_ctx.handleCrudAction(...arguments, row.ID))
                  }, null, 8, ["onActionCrud"]),
                  _createElementVNode("div", {
                    class: "btn btn-icon btn-sm btn-active-icon-primary",
                    onClick: ($event: any) => (_ctx.changeStatus(row.ID, !row.isActive)),
                    "data-bs-toggle": "tooltip",
                    "data-bs-placement": "bottom",
                    title: _ctx.$t(`TOOLTIPS.${row.isActive ? 'DESACTIVE': 'ACTIVE'}`)
                  }, [
                    (row.isActive)
                      ? (_openBlock(), _createElementBlock("i", _hoisted_2))
                      : (_openBlock(), _createElementBlock("i", _hoisted_3))
                  ], 8, _hoisted_1)
                ]),
                _: 2
              }, 1032, ["dataRow"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["headers"])
      ]),
      _: 1
    }, 8, ["labels", "numItemsPaginator", "selectedPage", "itemsPerPage", "fnCallbackChangePage", "fnCallbackCrudAction", "fnCallbackSearchItems"]),
    _createElementVNode("button", _hoisted_4, null, 512),
    _createVNode(_component_NewWayToPayForm, { formType: _ctx.modeForm }, null, 8, ["formType"])
  ]))
}