
import { defineComponent } from "vue"
import DynamicContentModal from "@/components/modals/general/DynamicContentModal.vue"
import { FormTypes } from "@/core/enums/form-types"
import NewWayToPayForm from "../forms/NewWayToPayForm.vue"
import { hideModalById } from "@/core/helpers/dom"

export default defineComponent({
    components:{
        DynamicContentModal
        , NewWayToPayForm
    }
    , props: {
        formType: {//indica como se comportara el formulario
            type: Number
            , default: FormTypes.Create
        }
    }
    , setup(){
        const closeModal = () => {
            hideModalById("#new-way-to-pay-form");
        };
        return {
            FormTypes
            , closeModal
        }
    }
})
